/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import './style.css'
import { TwitterPicker } from 'react-color'
import axios from 'axios'
import { getUserByToken } from '../../auth/redux/AuthCRUD'
import { PageTitle } from '../../../../_metronic/layout/core'
import { matchConsumerId } from '../../utility'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import { NotificationManager } from 'react-notifications';
import { toAbsoluteUrl } from '../../../../_metronic/helpers'


export function Branding() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any
  let file: any
  let setFiles: any
    ;[file, setFiles] = useState([])
  const [logoLink, setLogoLink] = useState('')
  const [bgColor, setBgColor] = useState('#315b7d')
  const [disable, setDisable] = useState(true);
  const [bgImageLink, setBgImageLink] = useState('');
  const [logoImageLink, setLogoImageLink] = useState('');

  const onSubmit = (e: any) => {
    console.clear();
    console.log(user)
    e.preventDefault();
    if (logoLink || bgColor) {
      setLoading(true)
      const formData = new FormData()
      formData.append('bgColor', bgColor);
      let isLogoValid = true;
      let isBGValid = true;
      let logoMxSize = 100000;
      let bgMxSize = 200000;
      let message = '';
      let bgAdded = false;
      if (Object.keys(file) && Object.keys(file).length > 0) {
        for (const fName in file) {
          let fileToSend = file[fName];
          console.log({ fName })
          if (fName == 'bg_image') {
            console.log('im in here')
            if (fileToSend.size > bgMxSize) {
              message = `Background image size should be less than 200kb`
              isBGValid = false;
            }
            bgAdded = true;
          }
          //changes
          if (fName == 'logo_img') {

            if (fileToSend.size > logoMxSize) {
              isLogoValid = false;
              message = `Logo image size should be less than 100kb`

            }
          }
          formData.append(`${fName}`, fileToSend);
        }
      }
      formData.append('logoLink', logoLink || '');
      formData.append('consumerId', user.user.consumerId);

      if(!bgAdded) formData.append('bg_image', '');

      // console.log({formData});
      console.log({ isLogoValid, isBGValid })
      if (!isLogoValid || !isBGValid) {
        NotificationManager.info(message, '', 5000);
        setLoading(false);
        return false;
      }
      axios
        .post(`${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/branding-preview`, formData)
        .then((response) => {
          setLoading(false)
          NotificationManager.success('Meeting UI settings have been updated successfully!', '', 5000);
        })
    } else {
      NotificationManager.info('please select fields', '', 5000);
    }
  }

  const { user: { consumerId } } = user;
  console.log({ user })
  useEffect(() => {

    console.log({ consumerId })
    if (consumerId) {
      matchConsumerId(consumerId, dispatch, auth);
      (() => {
        axios.get(`${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/consumer/branding-details/${consumerId}`).then(res => {
          console.log({ res })
          const data = res.data;
          const { backgroundColor, logoClickUrl, logoImageUrl, backgroundImageUrl } = data;
          if (backgroundColor !== "") {
            setBgColor(backgroundColor);
          }
          setLogoLink(logoClickUrl);
          if (logoImageUrl) {
            setLogoImageLink(logoImageUrl);
          } if (backgroundImageUrl) {
            setBgImageLink(backgroundImageUrl)
          }
        }).catch(err => {
          console.log({ err })
        })
      })()
    }
  }, [consumerId])

  const changeHandlerLogo = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setFiles({
        ...file,
        logo_img: event.target.files[0],
      })
      setDisable(false)
    }
  }
  const changeHandlerBG = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setFiles({
        ...file,
        bg_image: event.target.files[0],
      })
      setBgColor('')
      setDisable(false)
    }

  }

  const logoLinkOnChange = (event: any) => {
    setLogoLink(event.target.value)
    setDisable(false)
  }
  const handleBrandingVariation = (event: any) => {
    setBgColor(event.hex)
    setBgImageLink('');
    setDisable(false)
    if (Object.keys(file).length > 0) {
      delete file['bg_image']
      setFiles({
        ...file
      })
    }
  }

  console.log('ffff', file);
  return (
    <>
      <header id='header'>
        <div className='container-fluid'>
          <div className='row justify-content-center align-items-center'>
            <div className='col-lg-9 col-md-6 col-10'>
              <div className='row'>
                <div className='col-md-6'>
                  <form className='form' onSubmit={onSubmit}>
                    <div className='card-body' style={{ marginBottom: '16px' }}>
                      <div className='form-group'>
                        <label>Add your company Logo:</label>
                        <input
                          type='file'
                          name='logo_img'
                          accept='image/*'
                          className='form-control'
                          onChange={changeHandlerLogo}
                        />
                        <span className='form-text text-muted'>File size, up to 100KB</span>
                      </div>
                      <div className='separator separator-dashed my-5' />
                      <div className='form-group'>
                        <label>Website to route participants if they click on the logo:</label>
                        <input
                          type='text'
                          value={logoLink}
                          className='form-control'
                          placeholder='e.g. http://www.mycompany.com'
                          name='logo_link'
                          onChange={logoLinkOnChange}
                        />
                        <span className='form-text text-muted'>
                        </span>
                      </div>
                      <div className='separator separator-dashed my-5' />

                     
                      <span className='form-text text-muted'>Select either a background image or a background color.</span>
                      
                        <br />
                        <br />

                      <div className='form-group'>
                        <label>Background Image:</label>
                        <input
                          type='file'
                          accept='image/*'
                          name='logo_img'
                          className='form-control'
                          onChange={changeHandlerBG}

                        />
                        <span className='form-text text-muted'>File size, up to 200KB</span>
                       
                      </div>
                      <div className='separator separator-dashed my-5' />
                      <div className='form-group'>
                        <label style={{ marginBottom: '16px' }}>Background Color:</label>
                        <TwitterPicker value={bgColor} onChange={(e: any) => handleBrandingVariation(e)} />
                      </div>
                    </div>
                    <div className='card-footer'>
                      {!loading ? (
                        <button type="button" className=' secondary-button' onClick={onSubmit} disabled={disable}>
                          Submit
                        </button>
                      ) : (
                        <button type='button' className=' secondary-button'  >Loading....</button>
                      )}
                    </div>
                  </form>
                </div>
                <div className='col-md-6 header-right my-auto'>
                  <h6
                    className='mb-3'
                    style={{ marginLeft: '20px', fontFamily: 'Roboto, sans-serif' }}
                  >
                    Meeting UI preview:
                  </h6>
                  <div
                    className='images-box circular--portrait'
                    style={{ backgroundColor: bgColor, zIndex: -2 }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        zIndex: -1,
                      }}
                    >
                      {file.bg_image ? (
                        <img
                          src={window.URL.createObjectURL(file.bg_image)}
                          alt='no-img'
                          style={{ height: '350px' }}
                        />
                      ) : (
                        bgImageLink ?
                          <img
                            src={bgImageLink}
                            alt='no-img'
                            style={{ height: '350px' }}
                          /> :
                          <div
                            style={{ backgroundColor: bgColor, zIndex: -2 }}
                          ></div>
                      )}
                    </div>
                    {console.log('logoImageLink',logoImageLink)}
                    <div className='logo'>
                      {file.logo_img ? (
                        <img src={window.URL.createObjectURL(file.logo_img)} alt='no-img' />
                      ) : (
                        <img src={logoImageLink ? logoImageLink : toAbsoluteUrl('/media/avatars/logo.png')}
                          alt='no-img' />
                      )}
                    </div>
                    <div className='row justify-content-center'>
                      <div className='col-3 g-1 circleImage' style={{ backgroundColor: bgColor }}>
                        <h1 style={{ color: 'white', fontSize: '60px' }}>A</h1>
                      </div>
                      <div></div>
                    </div>
                    <div style={{position:"absolute",top:'85%',width:'100%'}}>
                    <img  src={toAbsoluteUrl('/media/toolbar.png')} alt="img" />
                    
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
