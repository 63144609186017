/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Route, Routes} from 'react-router-dom'
import {Branding} from './components/branding'

const BrandingPage = () => (
  <Routes>
    <Route>
      <Route path='branding' element={<Branding />} />
    </Route>
  </Routes>
)

export {BrandingPage}
