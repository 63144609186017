/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import {useDispatch} from 'react-redux'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {matchConsumerId} from '../../utility'
import './style.css'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import axios from 'axios'
import {useLocation} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
export interface itemObject {
  inviteeEmail?: string
  userRole?: string
  status?: string
  inviteeRole?: string
}
export function Team() {
  const [roles, setRoles] = useState([])
  const [userRole, setUserRole] = useState('')
  const [invitedUsers, setInvitedUsers] = useState([])
  const [email, setEmail] = useState('')
  const dispatch = useDispatch()
  const query = useLocation()
  const location = query.search || ''

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any
  const {
    user: {id},
  } = user

  useEffect(() => {
    console.log({id})
    if (id) {
      matchConsumerId(id, dispatch, auth)
    }
  }, [id])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/get-invitee${location}`)
      .then((response) => {
        setInvitedUsers(response.data.invitee)
      })
  }, [])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/get-roles`).then((response) => {
      setRoles(response.data.roles)
    })
  }, [])

  const onChangeUserRole = (e: any) => {
    setUserRole(e.target.value)
  }

  const onChangeUserEmail = (e: any) => {
    setEmail(e.target.value)
  }

  const cancelInviteOrDelete = (type: string, email?: string) => {
    let url
    if (type === 'CANCELLED') {
      confirmAlert({
        title: 'Confirm to Cancel',
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              url = `${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/cancel-invite`
              axios.get(`${url}?email=${email}`).then((response) => {
                alert(`invite has been ${type}`)
                window.location.reload()
              })
            },
          },
          {
            label: 'No',
            onClick: () => {
              console.log('No')
            },
          },
        ],
      })
    } else {
      confirmAlert({
        title: 'Confirm to Delete',
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              url = `${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/delete-invite`
              axios.get(`${url}?email=${email}`).then((response) => {
                alert(`invite has been ${type}`)
                window.location.reload()
              })
            },
          },
          {
            label: 'No',
            onClick: () => {
              console.log('No')
            },
          },
        ],
      })
    }
  }
  const onSubmit = (e: any) => {
    e.preventDefault()
    const obj = {
      userRoles: userRole,
      email: email,
    }
    axios
      .post(`${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/send-invite/${location}`, obj)
      .then((response) => {
        alert('invite has been sent successfully')
        window.location.reload()
      })
  }
  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1)
  return (
    <div className='container'>
      <div className='heading'>
        <PageTitle breadcrumbs={[]}>Team Managemant</PageTitle>
      </div>
      <div className='row' style={{marginBottom: '16px'}}>
        <div className='col-6'>
          <p className='para-1'>{invitedUsers && invitedUsers.length} Member(s)</p>
        </div>
        <div className='col-6 button-invite-2' style={{textAlign: 'right'}}>
          <button
            type='button'
            className='secondary-button btn btn-sm'
            data-bs-toggle='modal'
            data-bs-target='#staticBackdrop'
          >
            Invite Member
          </button>
        </div>
      </div>
      <div>
        <table className='table table-hover table-striped'>
          <thead className='thead-light'>
            <tr>
              <th scope='col' className='text-left py-3 px-4'>
                Email
              </th>
              <th scope='col' className='text-left py-3 px-4'>
                Role
              </th>
              <th scope='col' className='text-left py-3 px-4'>
                Status
              </th>
              <th scope='col' className='text-left py-3 px-4'>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {invitedUsers && invitedUsers.length === 0 && (
              <tr>
                <td colSpan={4} className='text-center py-4'>
                  <h3
                    className='mb-0 font-weight-normal'
                    style={{fontFamily: 'Roboto', fontSize: '12px'}}
                  >
                    No Record Found
                  </h3>
                </td>
              </tr>
            )}
            {invitedUsers &&
              invitedUsers.length > 0 &&
              invitedUsers.map((item: itemObject) => (
                <tr key={item.inviteeEmail}>
                  <td className='py-3 px-4'>{item.inviteeEmail}</td>
                  <td className='py-3 px-4'>{capitalize(item.inviteeRole)}</td>
                  <td className='py-3 px-4'>{capitalize(item.status)}</td>
                  <td className='py-3 px-4'>
                    <span
                      className='cursor-pointer px-4 py-2 btn btn-secondary'
                      onClick={() => cancelInviteOrDelete('DELETED', item.inviteeEmail)}
                    >
                      <i className='bi bi-trash3-fill p-0'></i>
                    </span>

                    <span
                      className='cursor-pointer px-4 py-2 btn btn-secondary mx-4'
                      onClick={() => cancelInviteOrDelete('CANCELLED', item.inviteeEmail)}
                    >
                      <i className='bi bi-x-square-fill p-0'></i>
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* Button trigger modal */}
      {/* Modal */}
      <div
        className='modal fade'
        id='staticBackdrop'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex={-1}
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered d-flex justify-content-center'>
          <form style={{width: '70%'}} onSubmit={onSubmit}>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='staticBackdropLabel'>
                  Invite Member
                </h5>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                />
              </div>
              <div className='modal-body'>
                <div className='mb-3'>
                  <label htmlFor='exampleFormControlTextarea1' className='form-label'>
                    Role
                  </label>
                  <select
                    className='form-select'
                    aria-label='Default select example'
                    name='userRole'
                    onChange={onChangeUserRole}
                    value={userRole}
                  >
                    <option selected>Select role</option>
                    {roles &&
                      roles.length > 0 &&
                      roles.map((item) => <option value={item}>{capitalize(item)}</option>)}
                  </select>
                </div>

                <div className='mb-3'>
                  <label htmlFor='exampleFormControlInput1' className='form-label'>
                    Email address
                  </label>
                  <input
                    name='email'
                    onChange={onChangeUserEmail}
                    type='email'
                    className='form-control'
                    id='exampleFormControlInput1'
                    placeholder='name@example.com'
                    required
                  />
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='secondary-button btn btn-sm'
                  data-bs-dismiss='modal'
                >
                  Cancel
                </button>
                <button type='submit' className='secondary-button btn btn-sm'>
                  Invite
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
