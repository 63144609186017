/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { Dropdown1 } from '../../../_metronic/partials'
import { useLocation } from 'react-router'

const AccountHeader: React.FC = () => {
  const location = useLocation()
  const userInfo: any = JSON.parse(localStorage.getItem('user') || '{}')
  console.log('usererere', userInfo)
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='Metronic' />
              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {userInfo?.otherInfo?.firstName} {userInfo?.otherInfo?.lastName}
                  </a>
               
                </div>

              </div>


            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                    
                      <div className='fs-2 fw-bolder'>$ {userInfo?.user?.balance}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Overdue Amount</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                     
                      <div className='fs-2 fw-bolder'>$ {userInfo?.user?.estimatedTotal}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Estimated Usage</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                     
                      <div className='fs-2 fw-bolder'>$ {userInfo?.user?.billingAlertAmount}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Alert Limit</div>
                  </div>
                </div>
                <span style={{color: 'red', fontWeight: 'bold'}}>This information is updated every 2 hours</span>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export { AccountHeader }
