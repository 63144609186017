/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import './style.css'
import axios from 'axios'
import {toAbsoluteUrl} from '../../../../_metronic/helpers/AssetHelpers'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useDispatch} from 'react-redux'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {NotificationManager} from 'react-notifications'

import {matchConsumerId} from '../../utility'
import {KTSVG} from '../../../../_metronic/helpers'
var fileDownload = require('js-file-download')
export function TESTAPI() {
  const [data, setData] = useState('')
  const query = useLocation()
  const dispatch = useDispatch()
  const location = query.search || ''
  const Id = location.split('=')[1]
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any
  const {
    user: {id, domain},
  } = user
  useEffect(() => {
    console.log({id})
    if (id) {
      matchConsumerId(id, dispatch, auth)
    }
  }, [id])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/get-jwt-test/${Id}`)
      .then((response) => {
        console.log(response)
        setData(response.data.token)
      })
  }, [Id])

  const onSubmit = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/generate-api-key/${Id}`)
      .then((response) => {
        console.log('this is', response)
        fileDownload(response.data, 'cm-api-key.pem')
      })
  }
  const _remove = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/delete-pair/${Id}`)
      .then((response) => {
        setData(response.data.consumer)
        window.location.reload()
      })
  }

  return (
    <div className='container !direction !spacing'>
      {/*Heading*/}
      {/* <div className="head-1"> */}
      <PageTitle breadcrumbs={[]}>Test Keys</PageTitle>
      {/* </div> */}
      {/*Alert*/}
      {/* <div className="alert alert-1" role="alert"> */}
      {/* <div className="form-check form-switch">
      <input className="form-check-input" type="checkbox" name="" id="" role="switch" />
      <span className="text-1">Allow meeting participants to join unauthenticated</span> 
    </div> */}
      {/* </div> */}
      {/* <div className="alert" role="alert">
        <div className="row">
          <div className="col-6">
            <p>Your ConsumerId: <span className="appID">{location.split('=')[1] || 'test123'}</span>
            </p>
            <p>Your Domain: <span className="appID">try.clanmeeting.com</span>
            </p>
          </div>
          <div className="col-6 copy-button">
            <button type="button" className="btn"><i className="icon-copy" />
            </button>
          </div>
        </div>
      </div> */}

      <div className='row'>
        <label
          className='btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center justify-content-between col-md-4 col-12'
          htmlFor=''
          style={{cursor: 'default'}}
        >
          <span className='d-block fw-bold text-start'>
            <span className='text-dark fs-6'>
              Your domain: <span className='appID'>{domain}</span>
            </span>
          </span>
          <span
            className='menu-icon ms-4 cursor-pointer'
            onClick={(e) => {
              navigator.clipboard.writeText(domain)
              NotificationManager.info('domain copied to clipboard', '', 5000)
            }}
          >
            <KTSVG path='/media/icons/duotune/files/fil026.svg' className='svg-icon-2' />
          </span>
        </label>
      </div>

      <div className='row mt-4'>
        <label
          className='btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center justify-content-between col-md-4 col-12'
          htmlFor=''
          style={{cursor: 'default'}}
        >
          <span className='d-block fw-bold text-start'>
            <span className='text-dark fs-6'>
              Consumer ID :&nbsp;&nbsp;&nbsp; {location.split('=')[1] || 'test123'}
            </span>
          </span>
          <span
            className='menu-icon ms-4 cursor-pointer'
            onClick={(e) => {
              navigator.clipboard.writeText(location.split('=')[1] || 'test123')
              NotificationManager.info('consumer id copied to clipboard', '', 5000)
            }}
          >
            <KTSVG path='/media/icons/duotune/files/fil026.svg' className='svg-icon-2' />
          </span>
        </label>
      </div>
      {/* <label
        className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
        htmlFor=''
      >
        <span className='svg-icon svg-icon-4x me-4'>
          <img
            alt='Logo'
            className='logo'
            style={{width: '50px'}}
            src={toAbsoluteUrl('/media/avatars/blank.png')}
          />
        </span>

        <span
          className='d-block fw-bold text-start'
          onClick={(e) => {
            //handling copying of text.
            navigator.clipboard.writeText(
              `Domain : ${domain}, consumerId:${location.split('=')[1] || 'test123'}`
            )
            NotificationManager.info(`Copied to clipboard`, '', 5000)
          }}
        >
          <span className='text-dark fw-bolder d-block fs-3'>
            consumerId: {location.split('=')[1] || 'test123'}
          </span>
          <span className='text-muted fw-bold fs-6'>
            Your domain: <span className='appID'>{domain}</span>{' '}
          </span>
        </span>
      </label> */}

      {/*main-row-1*/}

      {/*Learn-how to row*/}
      {data ? (
        <div className='head-3'>
          <h4 style={{fontFamily: 'Roboto'}}>Test JWT</h4>
          <textarea
            className='form-control'
            style={{backgroundColor: 'lightgrey', height: '200px'}}
            id=''
            name=''
            rows={5}
            cols={150}
            defaultValue={data}
            readOnly
          />
          <br />
          <br />
          <span style={{fontWeight: 'bold', fontSize: '16px'}}>
            Use these static test JWTs to test your applications with {process.env.APP_NAME} API.
            Before moving to production, go to the{' '}
            <Link to={`/ssh/api?consumerId=${id}`}>API Keys</Link> section to generate these JWTs
            dynamically using your API key.
          </span>
        </div>
      ) : (
        <span>please wait token generation is in process</span>
      )}

      {/*Jits Row*/}

      {/*generate a jipsi JWT card row*/}

      {/*integration row*/}
    </div>
  )
}
