/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useMemo} from 'react'
import './style.css'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import {useLocation} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useDispatch} from 'react-redux'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {matchConsumerId, getToday} from '../../utility'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'

import CsvDownload from 'react-json-to-csv'
// Import
import 'react-confirm-alert/src/react-confirm-alert.css'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
export function MeetingUsageReport() {
  const [data, setData] = useState([])
  const [pending, setPending] = useState(true)
  // const [clear, setClear] = useState(false)
  const [dateSearchResults, setDateSearchResults] = useState([])
  const [dates, setDates] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    endDate: new Date(),
  })
  const [show, setShow] = useState(false)
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any
  const query = useLocation()
  const dispatch = useDispatch()
  const location = query.search || ''
  const Id = location.split('=')[1]

  const {
    user: {id},
  } = user

  useEffect(() => {
    console.log({id})
    if (id) {
      matchConsumerId(id, dispatch, auth)
    }
  }, [id])

  useEffect(() => {
    if (dates?.endDate && dates?.startDate) {
      getFilesFromApi()
    }
  }, [])

  const getFilesFromApi = async () => {
    return await axios
      .get(
        `${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/meeting-usage-report/${Id}?fromDate=${dates.startDate}&toDate=${dates.endDate}`
      )
      .then((response) => {
        setData(response.data.files)
        setShow(false)
        setPending(false)
      })
  }

  const dateSelected = (e: any) => {
    setDates({...dates, [e.target.name]: e.target.value})
  }

  const allData = [...data]
  const dateSearch = () => {
    if (dates.startDate && dates.endDate) {
      setData(allData)
      const start_date_timestamp = parseInt(new Date(dates.startDate).getTime().toString())
      const end_date_timestamp = parseInt(new Date(getToday()).getTime().toString())
      const dateSearchResults = allData.filter(
        (item: any) =>
          start_date_timestamp < parseInt(new Date(item.createdAt).getTime().toString()) &&
          end_date_timestamp > parseInt(new Date(item.createdAt).getTime().toString())
      )
      setShow(true)
      setDateSearchResults(dateSearchResults)
    }
  }

  const filterAndMap = (details) => {
    if (data.length > 0 && !show) {
      details = data
    } else if (dateSearchResults.length >= 0 && show) {
      details = dateSearchResults
    }
    return (
      details &&
      details.length > 0 &&
      details.map((item) => {
        let obj = {
          ['Created At']: item.createdAt,
          ['Time Zone']: item.displayTimeZone,
          ['Room Name']: item.roomName,
          Domain: item.domain,
          ['Participant Count']: item.participantCount,
          ['Meeting Minutes']: item.billableMinutes,
          ['Dynamic Participant Minutes']: item.participantMinutes,
          Participants: item.participantList,
          Recording: item.recordingOn,
        }
        return obj
      })
    )
  }
  const columns = useMemo(
    () => [
      {
        name: 'Created At',
        selector: (row: any) => row.createdAt,
        sortable: true,
      },
      {
        name: 'Time Zone',
        selector: (row: any) => row.displayTimeZone,
        sortable: true,
      },
      {
        name: 'Room Name',
        selector: (row: any) => row.roomName,
        sortable: true,
      },
      {
        name: 'Domain',
        selector: (row: any) => row.domain,
        sortable: true,
      },
      {
        name: 'Participant Count',
        selector: (row: any) => row.participantCount,
        sortable: true,
      },
      {
        name: 'Meeting Minutes',
        selector: (row: any) => row.billableMinutes,
        sortable: true,
      },
      {
        name: 'Dynamic Participant Minutes',
        selector: (row: any) => row.participantMinutes,
        sortable: true,
      },
      {
        name: 'Recording',
        selector: (row: any) => (row.recordingOn === true ? 'Yes' : 'No'),
        sortable: true,
      },
      {
        name: 'Participants',
        selector: (row: any) => row.participantList,
        sortable: true,
        grow: 2,
      },
    ],
    []
  )

  const labelStyles = {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '1rem',
  }

  // const onClear = (e: any) => {
  //   e.preventDefault()
  //   setPending(true)
  //   setDateSearchResults([])
  //   setDates({startDate: '', endDate: getToday().slice(0, 10)})
  //   setClear(!clear)
  // }

  const dataFormat: any = () => {
    if (data.length > 0 && !show) {
      return data
    } else if (dateSearchResults.length >= 0 && show) {
      return dateSearchResults
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Usage Report</PageTitle>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <div style={{display: 'flex', alignItems: 'center', width: '70%'}}>
          <label style={labelStyles}>Start date</label>
          <DatePicker
            selected={dates.startDate}
            onChange={(date) => {
              const selectedDate = new Date(date)
              console.log({selectedDate})
              const today = new Date()
              if (!(selectedDate > today)) {
                setDates({
                  ...dates,
                  startDate: selectedDate,
                })
              }
            }}
            className='form-control-sm'
          />
          <label style={{...labelStyles, marginLeft: '12px'}}>End date</label>
          <DatePicker
            selected={dates.endDate}
            onChange={(date) => {
              const selectedDate = new Date(date)
              console.log({selectedDate})
              const today = new Date()
              if (selectedDate < today) {
                console.log('im here')
                setDates({...dates, endDate: date})
              } else {
                console.log('in else')
              }
            }}
            className='form-control-sm'
          />

          <button
            className='secondary-button btn btn-sm'
            type='button'
            data-kt-element='send'
            onClick={dateSearch}
            style={{marginRight: '10px', marginLeft: '10px'}}
          >
            Search
          </button>
          {/* <a href='' className='secondary-button danger-button' onClick={(e) => onClear(e)}>
            clear
          </a> */}
        </div>
        <div>
          <CsvDownload
            data={filterAndMap(data)}
            filename={`${new Date()}-data.csv`}
            style={{
              //pass other props, like styles
              boxShadow: 'inset 0px 1px 0px 0px #009EF7',
              background: '#009EF7',
              backgroundColor: '#009EF7',
              borderRadius: '6px',
              border: '1px solid #009EF7',
              display: 'inline-block',
              cursor: 'pointer',
              color: '#ffffff',
              fontSize: '15px',
              fontWeight: 'bold',
              padding: '6px 24px',
              textDecoration: 'none',
              textShadow: '0px 1px 0px #009EF7',
            }}
          >
            Export to CSV
          </CsvDownload>
        </div>
      </div>

      <DataTable
        title={<h1 style={{fontSize: '1.35rem'}}>Meeting Usage Report</h1>}
        data={dataFormat()}
        columns={columns}
        pagination
        progressPending={pending}
        progressComponent={
          <div style={{fontSize: '1.35rem'}}>
            <div className='d-flex justify-content-center mb-5'>
              <div className='spinner-border text-primary'>
                <span className='sr-only'>Please wait...</span>
              </div>
            </div>
          </div>
        }
        // selectableRows
        // onSelectedRowsChange={handleChange}
      />
    </>
  )
}
