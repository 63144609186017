import React, {useState, useEffect, useMemo, useCallback} from 'react'
import axios from 'axios'
import {Navigate, Route, Routes, Outlet, useLocation} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Overview} from './components/Overview'
import {Settings} from './components/settings/Settings'
import ExportCSV from './components/ExportCSV'
import {billingsDataModel, billingAddressModel} from './models/billingsDataModel'
import DataTable from 'react-data-table-component'
import {AccountHeader} from './AccountHeader'
import BillingAlertModal from './BillingAlertModal'
import BillingContactModal from './BillingContactModal'
import {removeHyphens} from '../utility'
import './style.css'
import generatePDF from "../accounts/components/pdf";
const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Account',
    path: '/crafted/account/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AccountPage: React.FC = () => {
  const [overdueAmount, setOverdueAmount] = useState(0)
  const [estimatedUsage, setEstimatedUsage] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [invoicepaid,setInvoicePaid]=useState(false);
  const [showContactModal, setShowContactModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  let cardData: any
  let setCardData: any
    ;[cardData, setCardData] = useState({})
  const [loadBillings, setLoadBillings] = useState(false)
  const [data, setData] = useState<any>([]);
  const [costData, setCostData] = useState({
    meetingCost: 0,
    recordingCost: 0,
    storageCost: 0,
    totalEstimatedCost: 0
  });
  const [dataBreakup, setDataBreakUp] = useState<any>([])
  const [loadBillingAddress, setLoadBillingAddress] = useState(false);
  const [billingAddress, setBillingAddress] = useState<billingAddressModel>()
  const location = useLocation()
  // const consumerId = location.search.split("=").pop();
  const consumerId = 'nanothread'

  const fetchCardData = useCallback(async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/card-status?consumerId=${consumerId}`
    )
    console.log('rsssss', result)
    setCardData(result.data);
    
  }, [])
  useEffect(() => {
    fetchCardData()
      // make sure to catch any error
      .catch(console.error)
  }, [fetchCardData])
  const handleBillingContactModal = (isEdit: boolean) => {
    setIsEdit(isEdit)
    setShowContactModal(true)
  }

  function loadRazorpay(src: string) {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

  const KEY_ENV = document.domain === 'localhost'

  const onSuccessPayments = async (res: any) => {
    const reqObj = {
      paymentId: res.razorpay_payment_id,
      consumerId: consumerId,
      invoiceId:res.invoiceId,
    }
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/update-status-to-paid`,
      reqObj
    )
    console.log('rsssss', result);
    setInvoicePaid(true);
    setTimeout(() => {
      setInvoicePaid(false);
    },5000)
    fetchBillings();
  }
    const handleRazorPayPayment=async (amount:number,invoiceId:any)=>{
    const result = JSON.parse(localStorage.getItem('user') || '{}')
    const {
      user: {name, email, contact},
    } = result
    const res = await loadRazorpay('https://checkout.razorpay.com/v1/checkout.js')
    if (!res) {
      alert('Razorpay SDK failed to load. Check your internet connection.')
      return
    }
    const obj = {
      overdueAmount: amount,
    }
    const data = await axios
      .post(`${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/payments`, obj)
      .then((response) => {
        return {
          id: response.data.id,
          currency: response.data.currency,
          amount: response.data.amount,
        }
      })
    const options = {
      key: KEY_ENV ? 'rzp_test_yN2oqBSur8AHmB' : 'prod_key',
      order_id: data.id,
      currency: data.currency,
      amount: data.amount,
      name: 'Transaction',
      description: 'Test Transaction',
      handler: function (response: any) {
        console.log('sssss->succ',response)
        const {razorpay_order_id,razorpay_payment_id}=response;
        onSuccessPayments({
          invoiceId,
          razorpay_payment_id
        })
      },
      prefill: {
        name: {name},
        email: {email},
        contact: {contact},
      },
      theme: {
        color: '#3399cc',
      },
    }
    const _window = window as any
    const paymentObj = new _window.Razorpay(options)
    paymentObj.open()
  }
  async function displayRazorpay() {
    // fetch user details
    const result = JSON.parse(localStorage.getItem('user') || '{}')
    const {
      user: {name, email, contact},
    } = result
    const res = await loadRazorpay('https://checkout.razorpay.com/v1/checkout.js')
    if (!res) {
      alert('Razorpay SDK failed to load. Check your internet connection.')
      return
    }
    const obj = {
      overdueAmount: '1',
    }
    const data = await axios
      .post(`${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/payments`, obj)
      .then((response) => {
        return {
          id: response.data.id,
          currency: response.data.currency,
          amount: response.data.amount,
        }
      })
    const options = {
      key: KEY_ENV ? 'rzp_test_yN2oqBSur8AHmB' : 'prod_key',
      order_id: data.id,
      currency: data.currency,
      amount: data.amount,
      name: 'Transaction',
      description: 'Test Transaction',
      handler: function (response: any) {
        onSuccessPayments(response)
      },
      prefill: {
        name: {name},
        email: {email},
        contact: {contact},
      },
      theme: {
        color: '#3399cc',
      },
    }
    const _window = window as any
    const paymentObj = new _window.Razorpay(options)
    paymentObj.open()
  }
  

  const headers = [
    {label: 'Invoice #', key: 'number'},
    {label: 'Amount', key: 'amount'},
    {label: 'Domain', key: 'domain'},
    {label: 'Participants Count', key: 'participantsCount'},
    {label: 'Participant List', key: 'participantList'},
    {label: 'Participants Minutes', key: 'participantsMinutes'},
    {label: 'Billable Minutes', key: 'billableMinutes'},
    {label: 'Duration Minutes', key: 'durationInMinutes'},
  ]

  const billingsData = (data: any) => {
    const billings = [{}];
    console.log({datassss:data})
    // data=JSON.parse(localStorage.getItem('dataBreakUp') || '[]');
    if(data && data.length > 0) {

    for (let i = 0; i <= data.length - 1; i++) {
      billings[i] = {
        createdDate: new Date(data[i].createdAt).toDateString(),
        roomName: data[i].roomName,
        domain: data[i].domain,
        participantsCount: data[i].participantCount,
        participantList: data[i].participantList,
        participantsMinutes: data[i].participantMinutes,
        billableMinutes: data[i].billableMinutes,
        durationInMinute: data[i].durationInMinutes,
      }
    }
  
    return billings
  }
  }
  const clickToGeneratePDF = (type='') => {
    const data: any = JSON.parse(localStorage.getItem('json') || '{}')
    if(type == 'excel') {
      return data;
    } else {
      generatePDF(billingsData(data))
    }
   
  }
  const actionsFunction = (
    <ExportCSV csvHeaders={headers} csvData={clickToGeneratePDF('excel')} fileName='Billings_Information.csv' />
  )

  const dataFormat: any = (source: string) => {
    if(source === 'breakup' && dataBreakup && dataBreakup.json && dataBreakup?.json.length > 0) {
      localStorage.setItem('dataBreakUp',JSON.stringify(dataBreakup?.json))
      return dataBreakup?.json
    }
    else if (data && data.json && data?.json.length > 0) {
      return data?.json
    }
  }
const handleRazorPay =() =>{

}
 
  const columns = useMemo(
    () => [
      {
        name: 'Invoice #',
        selector: (row: any) => (row.number),
        sortable: true,
      },
      {
        name: 'Amount',
        selector: (row: any) => row.amount,
        sortable: true,
      },
      {
        name: 'Date',
        selector: (row: any) => new Date(row.date).toDateString(),
        sortable: true,
        grow: 2,
      },
      {
        name: 'Status',
        cell: (row: any) =>  <span className={`badge ${row.status == "unpaid" ? 'badge-warning': 'badge-success'} `}>{row.status=='unpaid' ? 'Un Paid' : 'Paid' } </span> ,
        sortable: true,
        grow: 2,
      },
      {
        name: 'PDF Download',
        button: true,
        cell: (row:any) => 
         <button className="btn btn-sm btn-light-primary fw-bolder" onClick={() => {
          
          row.status === 'unpaid' ?  handleRazorPayPayment(row.amount,row._id) : clickToGeneratePDF()
        }}>
          {
            row.status==="unpaid" ? 'Pay' :'Download'
          }
       
        </button>
        ,
      }
    ],
    []
  )


  
  const BreakupColumns = useMemo(
    () => [
      {
        name: 'billableMinutes',
        selector: (row: any) => (row.billableMinutes),
        sortable: true,
      },
      {
        name: 'durationInMinutes',
        selector: (row: any) => row.durationInMinutes,
        sortable: true,
      },
      {
        name: 'participantCount',
        selector: (row: any) => row.participantCount,
        sortable: true,
        grow: 2,
      },
      {
        name: 'participantMinutes',
        selector: (row: any) => row.participantMinutes,
        sortable: true,
        grow: 2,
      },
       {
        name: 'participantList',
        selector: (row: any) => row.participantList,
        sortable: true,
        grow: 2,
      },
      {
        name: 'roomName',
        selector: (row: any) => row.roomName,
        sortable: true,
        grow: 2,
      }
    ],
    []
  )
  
  const fetchBillings = async () => {
    setLoadBillings(true)
    const data = await axios
      .get(
        `${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/get-invoices?consumerId=${consumerId}`
      )
      .then((response) => {
        setData(response.data)
        localStorage.setItem('json', JSON.stringify(response.data.json))
      })
      .finally(() => {
        setLoadBillings(false)
      })
  }

  const fetchMonthToDateInvoice = async () => {
    // setLoadBillings(true)
    const data = await axios
      .get(
        `${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/billing-usage-details?consumerId=${consumerId}`
      )
      .then((response) => {
        // setData(response.data)
        // console.log({response})
        const {data}=response.data;
        console.log({data})
        setCostData(data);
        // localStorage.setItem('json', JSON.stringify(response.data.json))
      })
      .finally(() => {
        setLoadBillings(false)
      })
  }
  const fetchBillingBreakup = async (fromDate : any) => {
    setLoadBillings(true)
    const data = await axios
      .get(
        `${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/get-billing-details?consumerId=${consumerId}&invoiceDate=${fromDate}&source=web`
      )
      .then((response) => {
        setDataBreakUp(response.data)
        localStorage.setItem('json', JSON.stringify(response.data.json))
      })
      .finally(() => {
        setLoadBillings(false)
      })
  }


  const handleInvoiceDate = (dateInput: string) => {
    const fromDate = removeHyphens(dateInput)
    fetchBillingBreakup(fromDate || '20220717')
  }

  const fetchBillingAddress = () => {
    setLoadBillingAddress(true)
     axios
      .get(
        `${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/get-updated-billing-details?consumerId=${consumerId}`
      )
      .then((response) => {
        setBillingAddress(response.data.info.billingAddress)
      })
      .catch((err) => {
        console.log('Error: ', err)
      })
      .finally(() => {
        setLoadBillingAddress(false)
      })
  }

  const editBillingAddress = async (editedBillingAddressObj: billingAddressModel) => {
    setLoadBillingAddress(true)
    const obj = {
      email:  editedBillingAddressObj?.email,
      firstName: editedBillingAddressObj?.firstName,
      lastName: editedBillingAddressObj?.lastName,
      company: editedBillingAddressObj?.company,
      city: editedBillingAddressObj.city,
      country: editedBillingAddressObj.country,
      line1: editedBillingAddressObj.line1,
      line2: editedBillingAddressObj.line2,
      state: editedBillingAddressObj.state,
      taxId: editedBillingAddressObj.taxId,
      zipcode: editedBillingAddressObj.zipcode,
      phone: editedBillingAddressObj.phone,
      consumerId: consumerId
    }
    await axios
      .post(`${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/add-update-billing-details`, obj)
      .then((response) => {
        setBillingAddress(response.data.info.billingAddress)
      })
      .catch((err) => {
        console.log('Error: ', err)
      })
      .finally(() => {
        setLoadBillingAddress(false)
        setShowContactModal(false)
      })
  }

  const callEditBillingAddress = (editedBillingAddressObj: billingAddressModel) => {
    editBillingAddress(editedBillingAddressObj)
  }

  useEffect(() => {
    const result = JSON.parse(localStorage.getItem('user') || '{}')
    const {
      user: {balance, estimatedTotal},
    } = result
    setOverdueAmount(balance)
    setEstimatedUsage(estimatedTotal)
    // fetch all billings with respect to current date
    const currentDate = new Date()
    const day = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate()
    const month =
      currentDate.getMonth() < 10 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1
    const year = currentDate.getFullYear()
    const str = `${year}-${month}-${day}`
    const initialFromDate = removeHyphens(str);
    fetchMonthToDateInvoice();
    fetchBillings()
    fetchBillingBreakup(initialFromDate)
    fetchBillingAddress();
  }, [])

  function isEmptyObject(obj: any) {
    return JSON.stringify(obj) === '{}'
  }

  return (
    // <Routes>
    //   <Route
    //     element={
    //       <>
    //         <AccountHeader />
    //         <Outlet />
    //       </>
    //     }
    //   >
    //     <Route
    //       path='overview'
    //       element={
    //         <>
    //           <PageTitle breadcrumbs={accountBreadCrumbs}>Overview</PageTitle>
    //           <Overview />
    //         </>
    //       }
    //     />
    //     <Route
    //       path='settings'
    //       element={
    //         <>
    //           <PageTitle breadcrumbs={accountBreadCrumbs}>Settings</PageTitle>
    //           <Settings />
    //         </>
    //       }
    //     />
    //     <Route index element={<Navigate to='/crafted/account/overview' />} />
    //   </Route>
    // </Routes>
    <>
      <AccountHeader />
      <Outlet />

      {/* ---- Separate components will be created while implementing functionality ---- */}

    

      <div className='card mb-5 mb-xl-10'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-lg-12'>
              <h3 className='mb-2'>Billing Alerts</h3>
              <div className='fs-5 mb-2'></div>
              <div
                className='fs-6 text-gray-600 fw-bold'
                style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
              >
                <span>
                  Setup automated billing alerts to receive emails when a specified usage amount is
                  reached
                </span>
                <span>
                  {' '}
                  <button className=' secondary-button' onClick={() => setShowModal(true)}>
                    Add Alert
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-lg-12'>
              <h3 className='mb-2'>Payment</h3>
              <div className='fs-5 mb-2'></div>
            {
              cardData && cardData.status !== 200 ? 
              <div
                className='fs-6 text-gray-600 fw-bold'
                style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
              >
                <span style={{color: 'red', fontSize: '16px', fontWeight: 'bold'}}>
                  Note: we will deduct 1$ from your account to validate the card which will be
                  refunded in 1-2 business days
                </span>
                <div>
                  <button type='button' className='btn btn-primary' onClick={displayRazorpay}>
                    Update Details
                  </button>
                </div>
              </div>:
              <div
              className='fs-6 text-gray-600 fw-bold'
              style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
            >
              <span style={{color: 'red', fontSize: '16px', fontWeight: 'bold'}}>
                Note: {cardData.message}
              </span>
              <div>
                <button type='button' className='btn btn-sm btn-light-primary fw-bolder' onClick={displayRazorpay} disabled={true}>
                  Updated successfully
                </button>
              </div>
            </div>
            }
              
            </div>
          </div>
        </div>
      </div>
      {/*<!-- Modal -->*/}
      <BillingAlertModal
        showModal={showModal}
        setShowModal={setShowModal}
        consumerId={consumerId}
      />

      {/* Billing Address Start */}

      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <div className='card-title'>
            <h3>Billing Contact</h3>
          </div>
        </div>
        <div className='card-body'>
          <div className='row gx-9 gy-6'>
            {loadBillingAddress && (
              <div className='d-flex justify-content-center mb-5'>
                <div className='spinner-border text-primary'>
                  <span className='sr-only'>Loading Billing Address</span>
                </div>
              </div>
            )}
            {!isEmptyObject(billingAddress) ? (
              <div className='col-xl-6'>
                <div className='card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6'>
                  <div className='d-flex flex-column py-2'>
                    <div className='d-flex align-items-center fs-5 fw-bolder mb-3'>
                      Billing Address
                    </div>
                    <div className='fs-6 fw-bold text-gray-600'>
                      Company: {billingAddress?.company}
                      <br />
                      Address: {billingAddress?.line1}
                      <br />
                      {billingAddress?.line2}
                      <br />
                      City: {billingAddress?.city}
                      <br />
                      State: {billingAddress?.state}
                      <br />
                      Zipcode: {billingAddress?.zipcode}
                      <br />
                      Country: {billingAddress?.country}
                    </div>
                  </div>
                  <div className='d-flex align-items-center py-2'>
                    <button
                      type='reset'
                      className=' secondary-button'
                    >
                      Delete
                    </button>
                    <button
                      className='secondary-button danger-button'
                      onClick={() => handleBillingContactModal(true)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className='col-xl-6'>
                <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed flex-stack h-xl-100 mb-10 p-6'>
                  <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                    <div className='mb-3 mb-md-0 fw-bold'>
                      <h4 className='text-gray-900 fw-bolder'>Enter new Billing Address Here</h4>
                    </div>
                    <button
                      className='btn btn-primary px-6 align-self-center text-nowrap'
                      onClick={() => handleBillingContactModal(false)}
                    >
                      New Address
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Billing Address End */}

      {/*<!-- Modal -->*/}
      <BillingContactModal
        showContactModal={showContactModal}
        setShowContactModal={setShowContactModal}
        isEdit={isEdit}
        loadBillingAddress={loadBillingAddress}
        editBillingAddress={callEditBillingAddress}
        billingAddress={billingAddress}
      />

      {/* <!--begin::Billing History--> */}
      <div className='card'>
        <div className='card-header border-bottom border-gray-200'>
          <div className='card-title'>
            <h3 className='fw-bolder m-0'>Billing History & Payments</h3>
          </div>
          
          {/* <div className='d-flex flex-row align-items-center'>
            <label>Filter by Invoice Date</label>
            <input
              type='date'
              className='form-control'
              onChange={(e) => handleInvoiceDate(e.target.value)}
            />
          </div> */}
        </div>
        <div className='tab-content'>
        {invoicepaid === true && (
          <div className='mb-lg-15 ml-5 alert alert-success'>
            <div className='alert-text font-weight-bold'>
             Invoice paid successfully
            </div>
          </div>
        )}
          <DataTable
            data={dataFormat()}
            columns={columns}
            // actions={actionsFunction}
            pagination
            progressPending={loadBillings}
            progressComponent={<div style={{fontSize: '1.35rem'}}>Fetching Billing History...</div>}
          />
        </div>

        
      </div>   
      <div className='card'>
        <div className='card-header border-bottom border-gray-200'>
          <div className='card-title' style={{display: 'block'}}>
            {/* <h3 className='fw-bolder m-0'></h3> */}
            <div className='fw-bolder mb-2 text-dark'>Month to date Breakup</div>
            <div className='text-muted fw-bold fs-7'>{"0"+(new Date()).getMonth()}-To {(new Date()).getDate()}</div>
            
          </div>
        
          {/* <div className='d-flex flex-row align-items-center'>
            <label>Filter by Invoice Date</label>
            <input
              type='date'
              className='form-control'
              onChange={(e) => handleInvoiceDate(e.target.value)}
            />
          </div> */}
        </div>
        <div className='tab-content'>
          <div className='col-lg-12  p-7'>
            <h3 className='mb-2'></h3>
            <div className='fs-5 mb-2'></div>
            <table style={{ width: '100%',}} className='month-to-date-table table table-striped table-bordered table-hover-primary'>
              <tbody>
                <tr  style={{paddingLeft: '10px', paddingRight:"10px"}}>
                  <td className='px-3'>Meeting Cost</td>
                  <td  className='px-3' align='right'>{costData.meetingCost.toFixed(2)}$</td>
                </tr>
                <tr>
                  <td className='px-3'>Recording Cost</td>
                  <td className='px-3' align='right'>{costData.recordingCost.toFixed(2)}$</td>
                </tr>
                <tr>
                  <td className='px-3'>Storage Cost</td>
                  <td className='px-3' align='right'>{costData.storageCost.toFixed(2)}$</td>
                </tr>
                <tr >
                  <td className='px-3'>SubTotal</td>
                  <td className='px-3' align='right'>{costData.totalEstimatedCost.toFixed(2)}$</td>
                </tr>
                <tr>
                  <td className='px-3'>Total</td>
                  <td className='px-3' align='right'>{costData.totalEstimatedCost.toFixed(2)}$</td>
                </tr>
              </tbody>
            </table>
            {/* <div
              className='fs-6 text-gray-600 fw-bold'
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <span>
                Setup automated billing alerts to receive emails when a specified usage amount is
                reached
              </span>
              <span>
                {' '}
                <button className='btn btn-primary' onClick={() => setShowModal(true)}>
                  Add Alert
                </button>
              </span>
            </div> */}
          </div>
        </div>
      </div>

      {/* <!--end::Billing Address--> */}
    </>
  )
}

export default AccountPage
