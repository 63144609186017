
import jsPDF from "jspdf";
import "jspdf-autotable";
// Date Fns is used to format the dates we receive
// from our API call
import { format } from "date-fns";

// define a generatePDF function that accepts a tickets argument
const generatePDF = data => {
  // initialize jsPDF
  const doc = new jsPDF();
  console.log('dataaaaa', data)
  // define the columns we want and their titles
  const tableColumn = [
    {
    name:"Created Date",
    selector:"createdDate"
    },
    {
      name:"Room Name",
      selector:"roomName"
    },
    {
      name:"Domain",
      selector:"domain"
    },
    {
      name:"Participant Count",
      selector:"participantCount"
    },
    {
      name:"Participant List",
      selector:"participantList"
    },
    {
      name:"Participant Minutes",
      selector:"participantMinutes"
    },
    {
      name:"Billable Minutes",
      selector:"billableMinutes"
    }
  ];
  //,  "", "",", ""
  // define an empty array of rows
  const tableRows = [];

  // for each ticket pass all its data into an array
//  data && data.forEach(item => {
//     const invoiceInfo ={ 
//       createdDate:item.createdDate,
//       roomName:item.roomName,
//       domain:item.domain,
//       participantCount:item.participantCount,
//       participantList:item.participantList,
//       participantMinutes:item.participantMinutes,
//       billableMinutes:item.billableMinutes,
//       // called date-fns to format the date on the ticket
//     //   format(new Date(item.updatedAt), "yyyy-MM-dd")
//     }
//     // push each tickcet's info into a row
//     tableRows.push(invoiceInfo);
//   });
  // console.log({tableRows: tableRows,tableColumn})
  //here 


//   const temp_rows = tableRows.map((d1) =>
//   tableColumn
//     .slice(0, this.columns.length - 1)
//     .map((d2) =>d2)
//     .map((d3) => d1[d3])
// );
    const temp_rows = data.slice(0,100)
    .sort((a,b) =>{
      
        var c = new Date(a.createdDate);
        var d = new Date(b.createdDate);
        return d-c;
    })
    .map((d1) =>
    tableColumn
    .map((d2) =>{
      return d2.selector;
    })
      .map((d3) =>{
        return  d1[d3];
      })
    );
    let colmnsForExport=tableColumn.map((d) => d.name);
    // console.log({colmnsForExport})
    let docTableData ={
      head:[colmnsForExport],
      body:temp_rows
    };
    // console.log({docTableData})
  //here end

  // startY is basically margin-top
 
  doc.autoTable(docTableData);
  const date = Date().split(" ");
  // we use a date string to generate our filename.
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
  // ticket title. and margin-top + margin-left
  // doc.text("Invoice Information for the consumer", 14, 15);
  // we define the name of our PDF file.
  doc.save(`invoice_${dateStr}.pdf`);
};

export default generatePDF;