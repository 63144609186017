/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from 'react'
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';

const BillingAlertModal = ({ showModal, setShowModal, consumerId }) => {
  const [timeLoader, setTimeLoader] = useState(false);
  let ref: any;
  ref = useRef(null);
  const [disable, setDisabled] = useState(true)
  const [timeInterval, setTimeInterval] = useState('');
  let message: any;
  let setMessage: any;
  [message, setMessage] = useState({})
  const handleModalClose = async () => {
    setTimeLoader(true);
    const obj = {
      // NOTE: Add generic consumer ID here once we go live
      id: consumerId,
      billingAlertAmount: +timeInterval
    }
    await axios.post(`${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/set-billing-alert-amount`, obj)
      .then(response => {
        setTimeLoader(false);
        setMessage({
          color: response.data.status === 200 ? 'green' : 'red',
          message: response.data.message
        });
        ref.current.value = ''
        alert(response.data.message)
    
      })
      .catch((err) => {
        setTimeLoader(false);
        setMessage({
          color: 'red',
          message: err.message
        })
      })
  };
  const validateField = (val: string) => {
    if(val) {
      
      setTimeInterval(val);
      setDisabled(false);
    } else {
      setDisabled(true);
    }

  }
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Set amount limit to alert you via email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          timeLoader &&
          <div className="d-flex justify-content-center mb-5">
            <div className="spinner-border text-primary">
              <span className="sr-only">Saving...</span>
            </div>
          </div>
        }
      
        <div className="d-flex flex-row">
          <input
            className='form-control'
            style={{ marginRight: 5 }}
            id="amount"
            type='number'
            ref={ref}
            onChange={(e) => validateField(e.target.value)}
     
          />
          <button type="button" className="btn secondary-button" onClick={handleModalClose} disabled={disable}>
            Save
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default BillingAlertModal;
