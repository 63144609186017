/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import './style.css'
import axios from 'axios'
import {toAbsoluteUrl} from '../../../../_metronic/helpers/AssetHelpers'
import {NotificationManager} from 'react-notifications'

import {Link, useLocation} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useDispatch} from 'react-redux'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {matchConsumerId} from '../../utility'
import {KTSVG} from '../../../../_metronic/helpers'
var fileDownload = require('js-file-download')

export function SSHAPI() {
  const [data, setData] = useState('')
  const query = useLocation()
  const dispatch = useDispatch()
  const location = query.search || ''
  const Id = location.split('=')[1]
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/get-ssh-pair/${Id}`)
      .then((response) => {
        // console.log({response})
        if (response.data.consumer) {
          setData(response.data.consumer.publicKey)
        } else {
          window.location.href = '/dashboard'
        }
      })
  }, [Id])

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any
  const {
    user: {consumerId, domain},
  } = user
  console.log({user})
  useEffect(() => {
    if (consumerId) {
      matchConsumerId(consumerId, dispatch, auth)
    }
  }, [consumerId])

  const onSubmit = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/generate-api-key/${Id}`)
      .then((response) => {
        fileDownload(response.data.privateKeyContent, 'cm-api-key.pem')
      })
  }
  const _remove = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/delete-pair/${Id}`)
      .then((response) => {
        setData(response.data.consumer)
        window.location.reload()
      })
  }

  return (
    <div className='container !direction !spacing'>
      {/*Heading*/}
      <div className='head-1'>
        <PageTitle breadcrumbs={[]}>Api Keys</PageTitle>
      </div>
      {/*Alert*/}
      {/* <div className="alert alert-1" role="alert">
        <div className="form-check form-switch">
          <input className="form-check-input" type="checkbox" name="" id="" role="switch" />
          <span className="text-1">Allow meeting participants to join unauthenticated</span>
        </div>
      </div> */}
      {/* <div className="alert" role="alert">
        <div className="row">
          <div className="col-6">
            <p>Your ConsumerId: <span className="appID">{location.split('=')[1] || 'test123'}</span>
            </p>
            <p>Your Domain: <span className="appID">ap01.clanmeeting.com</span>
            </p>
          </div>
          <div className="col-6 copy-button">
            <button type="button" className="btn"><i className="icon-copy" />
            </button>
          </div>
        </div>
      </div> */}

      <div className='row'>
        <label
          className='btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center justify-content-between col-md-4 col-12'
          htmlFor=''
          style={{cursor: 'default'}}
        >
          <span className='d-block fw-bold text-start'>
            <span className='text-dark fs-6'>
              Your domain : <span className='appID'>{domain}</span>
            </span>
          </span>
          <span
            className='menu-icon ms-4 cursor-pointer'
            onClick={(e) => {
              navigator.clipboard.writeText(domain)
              NotificationManager.info('domain copied to clipboard', '', 5000)
            }}
          >
            <KTSVG path='/media/icons/duotune/files/fil026.svg' className='svg-icon-2' />
          </span>
        </label>
      </div>

      <div className='row mt-4'>
        <label
          className='btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center justify-content-between col-md-4 col-12'
          htmlFor=''
          style={{cursor: 'default'}}
        >
          <span className='d-block fw-bold text-start'>
            <span className='text-dark fs-6'>
              Consumer ID :&nbsp;&nbsp;&nbsp; {location.split('=')[1] || 'test123'}
            </span>
          </span>
          <span
            className='menu-icon ms-4 cursor-pointer'
            onClick={(e) => {
              navigator.clipboard.writeText(location.split('=')[1] || 'test123')
              NotificationManager.info('consumer id copied to clipboard', '', 5000)
            }}
          >
            <KTSVG path='/media/icons/duotune/files/fil026.svg' className='svg-icon-2' />
          </span>
        </label>
      </div>

      {/* <label className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center" htmlFor="">

        <span className="svg-icon svg-icon-4x me-4">
          <img
            alt='Logo'
            className='logo'
            style={{ width: '50px' }}
            src={toAbsoluteUrl('/media/avatars/blank.png')}
          />
        </span>
     
        <span className="d-block fw-bold text-start" onClick={e => {
          //handling copying of text.
          navigator.clipboard.writeText(`Domain : ${domain}, consumerId:${location.split('=')[1] || 'test123'}`)
          NotificationManager.info(`Copied to clipboard`, '', 5000);

        }}>
        
          <span className="text-dark fw-bolder d-block fs-6">Consumer Id: {location.split('=')[1] || 'test123'}</span>
          <span className="text-muted fw-bold fs-6">Your domain: <span className="appID">{domain}</span> </span>
        </span>
      </label> */}
      {/*main-row-1*/}

      {/*Learn-how to row*/}
      {data && (
        <div className='head-3'>
          {/* 
        <h4 className='heading'>Your Added Key</h4>
        <textarea className="form-control" style={{ backgroundColor: '#eee',height:'270px' }} id="" name="" rows={12}  cols={150} defaultValue={data} readOnly />

        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}>
          <button style={{ backgroundColor: '#614e6e',marginTop:'10px' }} className=' secondary-button' type="button" onClick={() => _remove()}>Remove</button>
        </div> */}
        </div>
      )}

      {/*Jits Row*/}
      <div className='head-3'>
        <h4 className='heading'>Your API Key (Private SSH Key File)</h4>
        <p className='para-3'>
          For testing the API integration with your application, you just need a{' '}
          <Link to={`/jwt/test?consumerId=${consumerId}`}>static Test JWT</Link> . Once you are
          happy with the testing, you can use the following API key file to generate short-lived
          JWTs with ready to use code snippets from this{' '}
          <a href='https://clanmeeting.com/docs/video-api-token-generation/generate-jwt'>page</a>
        </p>
      </div>
      {/*generate a jipsi JWT card row*/}
      <div className='row card-row'>
        <div className='col-lg-12 card-1'>
          <div className='card'>
            <div className='card-body'>
              <h5 className='card-title'>Generate and download a new API key</h5>
              <p className='card-text'>
                Click the below button to generate and download a new API key. Note that this would
                replace your existing key immediately and JWTs generated using the existing API key
                would not work anymore.
              </p>
              <button
                type='button'
                className=' secondary-button'
                data-bs-toggle='modal'
                data-bs-target='#staticBackdrop1'
              >
                Generate New Key
              </button>
              {/* Modal */}
              <div
                className='modal fade w-full'
                id='staticBackdrop1'
                data-bs-backdrop='static'
                data-bs-keyboard='false'
                tabIndex={-1}
                aria-labelledby='staticBackdropLabel2'
                aria-hidden='true'
              >
                <div className='modal-dialog modal-lg modal-dialog-centered d-flex justify-content-center'>
                  <form>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h5 className='modal-title' id='staticBackdropLabel2'>
                          Generate ssh key pair
                        </h5>
                        <button
                          type='button'
                          className='btn-close'
                          data-bs-dismiss='modal'
                          aria-label='Close'
                        />
                      </div>
                      <div className='modal-body'>
                        <div className='mb-3 p-3'>
                          <label htmlFor='exampleFormControlInput1' className='form-label'></label>
                          <a href='#'>
                            <span onClick={onSubmit}> Click to download Private Key </span>
                          </a>
                        </div>
                      </div>
                      <div className='modal-footer'>
                        <button type='button' className='secondary-button' data-bs-dismiss='modal'>
                          Close
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*integration row*/}
    </div>
  )
}
