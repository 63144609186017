/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Route, Routes} from 'react-router-dom'
import { TESTAPI } from './components/test-api'
const TESTAPIRouting = () => (
  <Routes>
    <Route>
      <Route path='test' element={<TESTAPI />} />
    </Route>
  </Routes>
)

export {TESTAPIRouting}
