/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'

const BillingContactModal = ({
  showContactModal,
  setShowContactModal,
  isEdit,
  loadBillingAddress,
  editBillingAddress,
  billingAddress,
}) => {
  const [initialState, setInitalState] = useState<any>({})

  useEffect(() => {
    setInitalState(billingAddress)
  }, [billingAddress])
  const handleBillingDetails = (e: any) => {
    e.preventDefault()
    const data = {
      email: e.target.elements.email.value,
      firstName: e.target.elements.firstName.value,
      lastName: e.target.elements.lastName.value,
      company: e.target.elements.company.value,
      line1: e.target.elements.line1.value,
      line2: e.target.elements.line2.value,
      country: e.target.elements.country.value,
      state: e.target.elements.state.value,
      city: e.target.elements.city.value,
      zipcode: e.target.elements.zipcode.value,
      phone: e.target.elements.phone.value,
      taxId: e.target.elements.taxId.value,
    }
    editBillingAddress(data)
    // clear data of from
    e.target.reset()
  }

  const heading = isEdit ? 'Edit ' : 'Add '
  const onInputchange = (e: any) => {
    setInitalState({
      [e.target.name]: e.target.value,
    })
  }
  console.log('ittttt', initialState)
  return (
    <Modal show={showContactModal} onHide={() => setShowContactModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{heading} Billing Contact Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loadBillingAddress && (
          <div className='d-flex justify-content-center mb-5'>
            <div className='spinner-border text-primary'>
              <span className='sr-only'>Saving...</span>
            </div>
          </div>
        )}
        <div>
          <form className='needs-validation d-grid gap-3' onSubmit={(e) => handleBillingDetails(e)}>
            <div className='col-12'>
              <label className='form-label'>
                Email <span className='text-muted'> *</span>
              </label>
              <input
                type='email'
                className='form-control'
                name='email'
                placeholder='clanmeetingvc@gmail.com'
                value={initialState?.email}
                onChange={onInputchange}
                required
              />
              <div className='invalid-feedback'>
                Please enter a valid email address for shipping updates.
              </div>
            </div>
            <div className='row g-3'>
              <div className='col-sm-6'>
                <label className='form-label'>
                  First name<span className='text-muted'> *</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  name='firstName'
                  value={initialState?.firstName}
                  onChange={onInputchange}
                  required
                />
                <div className='invalid-feedback'>Valid first name is required.</div>
              </div>
              <div className='col-sm-6'>
                <label className='form-label'>
                  Last name<span className='text-muted'> *</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  name='lastName'
                  value={initialState?.lastName}
                  onChange={onInputchange}
                  required
                />
                <div className='invalid-feedback'>Valid last name is required.</div>
              </div>
            </div>
            <div className='col-12'>
              <label className='form-label'>
                Company Name<span className='text-muted'> *</span>
              </label>
              <input
                type='text'
                className='form-control'
                name='company'
                value={initialState?.company}
                onChange={onInputchange}
              />
            </div>
            <div className='col-12'>
              <label className='form-label'>
                Address<span className='text-muted'> *</span>
              </label>
              <input
                type='text'
                className='form-control'
                name='line1'
                value={initialState?.line1}
                onChange={onInputchange}
              />
              <div className='invalid-feedback'>Valid Address is required.</div>
            </div>
            <div className='col-12'>
              <label className='form-label'>Address 2</label>
              <input
                type='text'
                className='form-control'
                name='line2'
                value={initialState?.line2}
                onChange={onInputchange}
              />
            </div>
            <div className='row g-3'>
              <div className='col-sm-6'>
                <label className='form-label'>
                  Country <span className='text-muted'> *</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  name='country'
                  value={initialState?.country}
                  onChange={onInputchange}
                  required
                />
                <div className='invalid-feedback'>Valid Country name is required.</div>
              </div>
              <div className='col-sm-6'>
                <label className='form-label'>
                  State<span className='text-muted'> *</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  name='state'
                  value={initialState?.state}
                  onChange={onInputchange}
                  required
                />
                <div className='invalid-feedback'>Valid State name is required.</div>
              </div>
            </div>
            <div className='row g-3'>
              <div className='col-sm-6'>
                <label className='form-label'>
                  City<span className='text-muted'> *</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  name='city'
                  value={initialState?.city}
                  onChange={onInputchange}
                  required
                />
                <div className='invalid-feedback'>Valid City name is required.</div>
              </div>
              <div className='col-sm-6'>
                <label className='form-label'>
                  Postal Code<span className='text-muted'> *</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  name='zipcode'
                  value={initialState?.zipcode}
                  onChange={onInputchange}
                  required
                />
                <div className='invalid-feedback'>Valid Zipcode is required.</div>
              </div>
            </div>
            <div className='col-12'>
              <label className='form-label'>
                Phone<span className='text-muted'> *</span>
              </label>
              <input
                type='text'
                className='form-control'
                name='phone'
                value={initialState?.phone}
                onChange={onInputchange}
                required
              />
              <div className='invalid-feedback'>Valid Contact number is required.</div>
            </div>
            <div className='col-12'>
              <label className='form-label'>
                Tax ID<span className='text-muted'> *</span>
              </label>
              <input
                type='text'
                className='form-control'
                name='taxId'
                value={initialState?.taxId}
                onChange={onInputchange}
                required
              />
              <div className='invalid-feedback'>Valid Taxid is required.</div>
            </div>
            <div className='text-center'>
              <button className='w-50 btn btn-primary btn-lg' type='submit'>
                Save
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default BillingContactModal
