/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Route, Routes} from 'react-router-dom'
import {Team} from './components/team'
const TeamPage = () => (
  <Routes>
    <Route>
      <Route path='team' element={<Team />} />
    </Route>
  </Routes>
)

export {TeamPage}
