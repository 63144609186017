/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'

import {useLocation} from 'react-router-dom'
import {register} from '../redux/AuthCRUD'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import axios from 'axios'

import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import qs from 'querystring'
import './style.css'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(1, 'Please enter minimum 1 character')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastname: Yup.string()
    .min(1, 'Please enter minimum 1 character')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  acceptTerms: Yup.bool().required('You must accept the terms and privacy policy'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  let message: any
  let setMessage: any
  ;[message, setMessage] = useState({})
  const dispatch = useDispatch()
  const location = useLocation()
  const [isEmailDisabled, setIsEmailDisabled] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        register(values.email, values.firstname, values.lastname, values.password, location.search)
          .then(({data: {api_token, msg, success}}) => {
            setLoading(false)
            setStatus(msg)
            setMessage({
              message: msg,
              color: success === false ? 'alert error-message' : 'alert alert-primary',
              code: true,
            })
            setSubmitting(false)
            // dispatch(auth.actions.register(api_token))
          })
          .catch((err) => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Registration process has broken')
            setMessage({
              msg: 'Registration process has broken' + err.message,
              color: 'error-message',
              code: false,
            })
          })
      }, 1000)
    },
  })

  // useEffect(() => {
  //   PasswordMeterComponent.bootstrap()
  //   const params = qs.parse(window.location.search)
  //   const {email} = params
  //   console.log({params, email})
  //   if (email) {
  //     ;(() => {
  //       //check-invite
  //       axios
  //         .get(`${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/check-invite?email=${email}`)
  //         .then((response) => {
  //           console.log({response})
  //           const {status} = response.data
  //           if (status === 'ok') {
  //             formik.setFieldValue('email', email)
  //             setIsEmailDisabled(true)
  //           } else {
  //             window.location.href = '/'
  //           }
  //         })
  //     })()
  //   }
  // }, [formik])

  useEffect(() => {
    PasswordMeterComponent.bootstrap()

    // Parse the URL parameters
    const paramsString = window.location.search.startsWith('?')
      ? window.location.search.slice(1)
      : window.location.search

    const params = qs.parse(paramsString)
    const {email} = params

    if (email) {
      ;(async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/check-invite?email=${email}`
          )

          const {status} = response.data

          if (status === 'ok') {
            formik.setFieldValue('email', email)
            setIsEmailDisabled(true)
          }
        } catch (error) {
          console.error('Error fetching invite status:', error)
        }
      })()
    }
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-7 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-1'>Create an account for free</h1>
        {/* end::Title */}

        {/* begin::Subtitle */}
        <div className='text-gray-400 fs-4 mb-4'>No credit card required</div>
        {/* end::Subtitle */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          Already have an account?
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Sign in here
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {/* begin::Action */}
      {/* <button type='button' className='btn btn-light-primary fw-bolder w-100 mb-10'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
          className='h-20px me-3'
        />
        <a href={`${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/auth/google`}>Sign in with Google</a>
      </button> */}
      {/* end::Action */}

      <div className='d-flex align-items-center mb-7'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>

      {formik.status && message.code && (
        <div className={`mb-lg-15 ${message.color}`}>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      {formik.status && !message.code && (
        <div className={`mb-lg-15 alert ${message.color}`}>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      {/* begin::Form group Firstname */}
      <div className='row fv-row'>
        <div className='col-xl-6 mb-7'>
          <label className='form-label fw-bolder text-dark fs-6'>First name</label>
          <input
            placeholder='First name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.firstname && formik.errors.firstname,
              },
              {
                'is-valid': formik.touched.firstname && !formik.errors.firstname,
              }
            )}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-6 mb-7'>
          {/* begin::Form group Lastname */}
          <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
          <input
            placeholder='Last name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('lastname')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.lastname && formik.errors.lastname,
              },
              {
                'is-valid': formik.touched.lastname && !formik.errors.lastname,
              }
            )}
          />
          {formik.touched.lastname && formik.errors.lastname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.lastname}</span>
              </div>
            </div>
          )}
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          disabled={isEmailDisabled}
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-7 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>Use 8+ characters including letters, numbers & symbols</div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            I agree to the{' '}
            <a href='https://clanmeeting.com/terms-and-conditions/' className='ms-1 link-primary'>
              terms
            </a>{' '}
            and{' '}
            <a href='https://clanmeeting.com/privacy-policy/' className='ms-1 link-primary'>
              privacy policy
            </a>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {formik.status && message.code && (
        <div className={`mb-lg-15 alert ${message.color}`}>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      {formik.status && !message.code && (
        <div className={`mb-lg-15 alert ${message.color}`}>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Create Account</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
    </form>
  )
}
