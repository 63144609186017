/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useMemo, useCallback} from 'react'
import {EmailShareButton, EmailIcon} from 'react-share'
import './style.css'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import {useLocation} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useDispatch} from 'react-redux'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {matchConsumerId} from '../../utility'
import {confirmAlert} from 'react-confirm-alert'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'

export function Recordings() {
  const [data, setData] = useState([])
  const [pending, setPending] = useState(true)
  const [selectedRows, setSelectedRows] = useState([])
  const [clear, setClear] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [searchR, setSearchResults] = useState([])
  const [dateSearchResults, setDateSearchResults] = useState([])
  const [dates, setDates] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    endDate: new Date(),
  })
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any
  const query = useLocation()
  const dispatch = useDispatch()
  const location = query.search || ''
  const consumerId = location.split('=')[1]

  const [value, onChange] = useState(new Date())

  // const { user: { consumerId } } = user;

  useEffect(() => {
    if (consumerId) {
      matchConsumerId(consumerId, dispatch, auth)
    }
  }, [consumerId])

  useEffect(() => {
    getFilesFromApi()
  }, [clear])

  const getFilesFromApi = async () => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/get-recordings/${consumerId}`)
      .then((response) => {
        setData(response.data.files)
        localStorage.setItem(
          'authorizationToken',
          response?.data?.data?.credentials?.authorizationToken
        )
        localStorage.setItem('downloadUrl', response?.data?.data?.credentials?.downloadUrl)
        localStorage.setItem('apiUrl', response?.data?.data?.credentials?.apiUrl)
        setPending(false)
        const start_date_timestamp = new Date(
          new Date(new Date().setDate(new Date().getDate() - 7))
        ).getTime()
        const end_date_timestamp = new Date(new Date()).getTime()

        const filteredData = data.filter((item: any) => {
          const itemDate = new Date(item.uploadTimestamp).getTime()
          return itemDate >= start_date_timestamp && itemDate <= end_date_timestamp
        })
        setDateSearchResults(filteredData)
      })
  }

  useEffect(() => {
    if (searchValue) {
      let getSearchResults = data.filter(
        (item: any) => item.fileName.toLowerCase().indexOf(searchValue) !== -1
      )
      setSearchResults(getSearchResults)
    }
  }, [searchValue])

  const getConfig = {
    url: localStorage.getItem('downloadUrl'),
    bucketName: 'clanmeeting-recordings',
    authToken: localStorage.getItem('authorizationToken'),
  }

  // useEffect(() => {
  //   console.log('selecteddddddd state', selectedRows);
  // }, [selectedRows]);

  const handleDownloadClick = (row: any) => {
    const config = {
      url: localStorage.getItem('downloadUrl'),
      bucketName: 'clanmeeting-recordings',
      authToken: localStorage.getItem('authorizationToken'),
    }
    window.open(
      `${config.url}/file/${config.bucketName}/${row.fileName}?Authorization=${config.authToken}`,
      '_blank'
    )
  }

  const yesClicked = async (file: any) => {
    const {fileId, fileName} = file

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL_BACKEND_LOCAL}/delete-file`,
      {
        data: {
          fileName: fileName,
          fileId: fileId,
          apiUrl: localStorage.getItem('apiUrl'),
          authTok: localStorage.getItem('authorizationToken'),
        },
      }
    )
    if (response.status == 200) {
      setClear(!clear)
    } else {
      console.log('Some Error', response)
    }
  }

  const noClicked = () => {}

  const handleDeleteClick = (row: any) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => yesClicked(row),
        },
        {
          label: 'No',
          onClick: noClicked,
        },
      ],
    })
  }

  // const handleChange = useCallback(state => {
  //   setSelectedRows(state.selectedRows);
  //   console.log('StateeeeeeeeeeeeEEEEEEEEE', state);
  // }, []);

  const searchNow = (e: any) => {
    setSearchValue(e.target.value.toLowerCase().substr(0, 20))
  }

  const dateSelected = (e: any) => {
    setDates({...dates, [e.target.name]: e.target.value})
  }

  // const allData = [...data]
  // const dateSearch = () => {
  //   if (dates.startDate && dates.endDate) {
  //     setData(allData)
  //     const start_date_timestamp = parseInt(new Date(dates.startDate).getTime().toString())
  //     const end_date_timestamp = parseInt(new Date(dates.endDate).getTime().toString())
  //     const dateSearchResults = allData.filter(
  //       (item: any) =>
  //         item.uploadTimestamp > start_date_timestamp && item.uploadTimestamp < end_date_timestamp
  //     )

  //     console.log('yo', dateSearchResults.length)

  //     setDateSearchResults(dateSearchResults)
  //   }
  // }
  const dateSearch = () => {
    if (dates.startDate && dates.endDate) {
      const start_date_timestamp = new Date(dates.startDate).getTime()
      const end_date_timestamp = new Date(dates.endDate).getTime()

      const filteredData = data.filter((item: any) => {
        const itemDate = new Date(item.uploadTimestamp).getTime()
        return itemDate >= start_date_timestamp && itemDate <= end_date_timestamp
      })

      setDateSearchResults(filteredData)
    }
  }

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: (row: any) => row.fileName.substring(row.fileName.indexOf('/') + 1),
        sortable: true,
        grow: 2,
      },
      {
        name: 'Size (in MB)',
        selector: (row: any) => (row.size / 1024 / 1024).toFixed(2) + ' Mb',
        sortable: true,
      },
      {
        name: 'Created',
        selector: (row: any) => new Date(row.uploadTimestamp).toDateString(),
        sortable: true,
      },
      {
        cell: (row: any) => (
          <div>
            <EmailShareButton
              style={{cursor: 'pointer'}}
              subject={'File Link'}
              body={`${getConfig.url}/file/${getConfig.bucketName}/${row.fileName}?Authorization=${getConfig.authToken}`}
              children={<EmailIcon size={24} />}
              url={''}
            />
            <i
              className='fas fa-download'
              style={{paddingLeft: '10px', cursor: 'pointer'}}
              onClick={() => handleDownloadClick(row)}
            ></i>
            <i
              className='fas fa-trash'
              style={{paddingLeft: '10px', cursor: 'pointer'}}
              onClick={() => handleDeleteClick(row)}
            ></i>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ],
    []
  )

  const labelStyles = {display: 'flex', alignItems: 'center', paddingRight: '1rem'}
  const onClear = (e: any) => {
    e.preventDefault()
    setPending(true)
    setDateSearchResults([])
    setDates({
      startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
      endDate: new Date(),
    })
    setClear(!clear)
  }
  const dataFormat: any = () => {
    // if (data.length > 0 && dateSearchResults.length <= 0) {
    //   return data
    // } else
    if (searchR.length > 0) {
      return searchR
    } else if (dateSearchResults.length >= 0 && searchR.length === 0) {
      return dateSearchResults
    }
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>Recordings</PageTitle>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <div style={{display: 'flex', alignItems: 'center', width: '70%'}}>
          <label style={labelStyles}>Start date</label>
          <DatePicker
            selected={dates.startDate}
            onChange={(date) => {
              const selectedDate = new Date(date)
              console.log({selectedDate})
              const today = new Date()
              if (!(selectedDate > today)) {
                setDates({
                  ...dates,
                  startDate: selectedDate,
                })
              }
            }}
            className='form-control-sm'
          />
          {/* <input
            type='date'
            className='form-control-sm form-control-flush ps-10'
            style={{ border: '1px solid gray', borderRadius: '2px', marginRight: '10px' }}
            value={dates.startDate} onChange={e => { dateSelected(e) }}
            name="startDate"
            data-kt-search-element='input'
          /> */}
          <label style={{...labelStyles, marginLeft: '12px'}}>End date</label>
          <DatePicker
            selected={dates.endDate}
            onChange={(date) => {
              const selectedDate = new Date(date)
              console.log({selectedDate})
              const today = new Date()
              if (selectedDate < today) {
                console.log('im here')
                setDates({...dates, endDate: date})
              } else {
                console.log('in else')
              }
            }}
            className='form-control-sm'
          />

          <button
            className='secondary-button btn btn-sm'
            style={{marginLeft: '10px', marginRight: '10px'}}
            type='button'
            data-kt-element='send'
            onClick={dateSearch}
          >
            Search
          </button>
          <button className='secondary-button btn btn-sm danger-button' onClick={(e) => onClear(e)}>
            Clear{' '}
          </button>
        </div>
        <div>
          <input
            type='text'
            className='form-control form-control-flush ps-10'
            style={{border: '1px solid gray', borderRadius: '10px', width: '15rem'}}
            name='search'
            value={searchValue}
            onChange={(e) => searchNow(e)}
            placeholder='Search...'
            data-kt-search-element='input'
          />
        </div>
      </div>

      <DataTable
        title={<h1 style={{fontSize: '1.35rem'}}>Recordings</h1>}
        data={dataFormat()}
        columns={columns}
        pagination
        progressPending={pending}
        noDataComponent='No Recordings Found'
        progressComponent={<div style={{fontSize: '1.35rem'}}>Loading...</div>}
        // selectableRows
        // onSelectedRowsChange={handleChange}
      />
    </>
  )
}
