/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Route, Routes } from 'react-router-dom'
import { MeetingUsageReport } from './components/meetingUsage';
const MeetingUsage = () => (
  <Routes>
    <Route>
      <Route path='usage' element={<MeetingUsageReport />} />
    </Route>
  </Routes>
)

export { MeetingUsage }
