import axios from 'axios'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/users/verify-token`
export const LOGIN_URL = `${API_URL}/users/login`
export const REGISTER_URL = `${API_URL}/users/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/users/forgot-password`
export const UPDATE_PASSWORD_URL = `${API_URL}/users/update-password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  parsed: string
) {
  return axios.post(`${REGISTER_URL}${parsed}`, {
    email,
    firstName: firstname,
    lastName: lastname,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post(REQUEST_PASSWORD_URL, {
    email,
  })
}
export function updatePassword(password: string, token: string) {
  return axios.post(`${UPDATE_PASSWORD_URL}?token=${token}`, {
    password,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
