import { CSVLink } from "react-csv";
import { Button } from "react-bootstrap";

const ExportCSV = ({ csvHeaders, csvData, fileName }) => {
  return (
    <CSVLink headers={csvHeaders} data={csvData} filename={fileName}>
      <Button variant="success">Export CSV</Button>
    </CSVLink>
  )
}

export default ExportCSV;
