/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Route, Routes } from 'react-router-dom'
import { Recordings } from './components/Recordings';
const Recordingss = () => (
  <Routes>
    <Route>
      <Route path='all' element={<Recordings />} />
    </Route>
  </Routes>
)

export { Recordingss }
