/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Route, Routes} from 'react-router-dom'
import { SSHAPI } from './components/ssh-api'
const SSHAPIRouting = () => (
  <Routes>
    <Route>
      <Route path='api' element={<SSHAPI />} />
    </Route>
  </Routes>
)

export {SSHAPIRouting}
