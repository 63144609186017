import {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import axios from 'axios'
import clsx from 'clsx'
import {Outlet} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
const API_URL = process.env.REACT_APP_API_URL || ''
export const ACTIVATE_USER = `${API_URL}/users/activate`

export function ActivateUser() {
  const [result, setData]: any = useState()
  const query = useLocation()
  const location = query.search || ''
  const token = location.split('?token=')[1]
  let navigate = useNavigate()
  const options = {
    headers: {accessToken: token},
  }
  useEffect(() => {
    async function verifyToken() {
      const result = await axios.get(ACTIVATE_USER, options)
      setData(result)
    }
    verifyToken()
  }, [token])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='#' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/logoLightNew.png')} className='h-45px' />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            id='kt_login_password_reset_form'
            noValidate
          >
            {/* begin::Heading */}
            <div className='text-center mb-10'>
              {/* begin::Title */}
              <h1 className='text-dark mb-3'>Email Verification Successful</h1>
              {/* end::Title */}

              {/* begin::Link */}
              <div className='text-gray-400 fw-bold fs-4'></div>
              {/* end::Link */}
            </div>
            {/* begin::Heading */}

            {result && !result.data.success ? (
              <div className='text-center mb-5'>
                <div className='fv-row mb-10'>
                  <label className='form-label fw-bolder text-gray-900 fs-6'></label>
                  <div className='alert-text font-weight-bold alert' style={{color: '#cc0000'}}>
                    {result.data.msg}
                  </div>
                </div>
              </div>
            ) : (
              <div className='text-center mb-5'>
                <div className='alert-text font-weight-bold alert alert-primary'>
                  {result && result.data.success && result.data.msg}
                </div>
              </div>
            )}

            {/* begin::Form group */}

            {/* end::Form group */}

            {/* begin::Form group */}

            {/* end::Form group */}

            {/* begin::Action */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary mb-5'
                onClick={() => navigate('/auth')}
              >
                {result && <span className='indicator-label'>Go To Login Page</span>}
                {!result && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>

            {/* end::Action */}

            <div className='d-flex flex-center flex-column-auto p-10'>
              <div className='d-flex align-items-center fw-bold fs-6'>
                <a
                  href='https://clanmeeting.com/about-us/'
                  className='text-muted text-hover-primary px-2'
                >
                  About
                </a>

                <a
                  href='https://clanmeeting.com/contact-us'
                  className='text-muted text-hover-primary px-2'
                >
                  Contact
                </a>

                {/* <a href='#' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a> */}
              </div>
            </div>
          </form>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}

      {/* end::Footer */}
    </div>
  )
}
