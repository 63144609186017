

export const matchConsumerId=(consumerId:any,dispatch:any,auth:any)=>{
  const queryParams = new URLSearchParams(window.location.search);
    const urlConsumerId = queryParams.get('consumerId');
    if (consumerId !== urlConsumerId) {
      // dispatch(auth.actions.logout());
      window.location.href='/dashboard'
    }
}


export const getToday=()=> {
  const local = new Date();
  local.setMinutes(local.getMinutes() - local.getTimezoneOffset());
  return local.toJSON();
}

export const removeHyphens = (input: string) => {
  return input.replace(/-/g, '');
}
